import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';

@Component({
  selector: 'app-mostrar-distribuidor',
  templateUrl: './mostrar-distribuidor.component.html',
  styleUrls: ['./mostrar-distribuidor.component.css']
})
export class MostrarDistribuidorComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;
  public distribuidores = [];
  public totalRegistro;

  //LOADING
  loading: boolean = true;

  constructor( private http:HttpClient ) { }

  ngOnInit() {
    this.getDataDistribuidor();
  }

  getDataDistribuidor() {
    const url: string = URL_SERVICIOS + '/distribuidor';
    this.distribuidores = [];
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.distribuidores = Object.values(data);
      this.totalRegistro = Object.keys(this.distribuidores[1]).length;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }

  Desactivar( id: any ) {
    let turn_off = ({
      condicion: 1,
    });
    const url: string = URL_SERVICIOS + '/activador/distribuidor/' + id;
    this.getDataDistribuidor();

    swal( {
      title: "Esta seguro de continuar?",
      text:  "Se va a deshabilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.http.put(url, turn_off, {headers: this.headers}).subscribe( ( data: any)  => {
                      // this.comunas = data;
                      this.getDataDistribuidor();
                    });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }

}
