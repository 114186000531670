import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import swal from 'sweetalert';


@Component({
  selector: 'app-mostrar-motor-desactivado',
  templateUrl: './mostrar-motor-desactivado.component.html',
  styleUrls: ['./mostrar-motor-desactivado.component.css']
})
export class MostrarMotorDesactivadoComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;

  public motoresDesactivadas;
  public totalRegistro;
  public motores = [];

  constructor(private  http: HttpClient ) { }

  ngOnInit() {
    this.getDataMotores();
  }

  getDataMotores() {
    const url: string = URL_SERVICIOS + '/motores/desactivados';
    this.motores = [];
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      // this.comunas = data;
      this.motores = Object.values(data);
      this.totalRegistro = Object.keys(this.motores[1]).length;

       console.log(this.motores);
      // console.log(this.facturas[1]);
    });
  }

  Activar( id: any ) {
    let turn_off = ({
         condicion: 0,
       });
 
     swal( {
         title: "Esta seguro de continuar?",
         text:  "Se va a habilitar este registro",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       } as any)
         .then((willDelete) => {
         if (willDelete) {
             const url: string = URL_SERVICIOS + '/activador/motor/' + id;
               this.http.put(url, turn_off , {headers: this.headers}).subscribe( ( data: any)  => {
                 this.motoresDesactivadas  = Object.values(data);
                 this.getDataMotores();
               });
           swal("La acción se ha completado ...", {
             icon: "success",
           });
         } else {
           swal("OK, vamos a esperar su respuesta");
         }
       });
 
   }

}
