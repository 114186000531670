import { Injectable } from '@angular/core';
import { Factura } from 'src/app/models/factura.model';
import { URL_SERVICIOS } from '../../config/config';
import { UsuarioService } from '../usuario/usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import swal from 'sweetalert';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class FacturaService {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  public facturas = [];
  pageActual: number = 1;
  public totalRegistro;


  constructor(public usuarioService: UsuarioService, private http: HttpClient) { }

  crearFactura( factura: Factura , header: any) {

    const url: string = URL_SERVICIOS + '/registrar/' + this.usuarioService.usuario[0].dealer_id;
    // console.log(factura);
    
    return this.http.post( url , JSON.parse(JSON.stringify(factura)), {headers: header } )
                              .map( (resp: any) => {  swal("Su registro de venta se ha completado, si quiere puede volver a registrar uno nuevo ...", {
                                icon: "success",
                              }); } )
                              .catch( err => {
                                swal('Error', err.error.error.sqlMessage , 'error');
                                return Observable.throw( err )});
  }

  updateFactura( factura: any, idx: string, header: any) {

    const url: string = URL_SERVICIOS + '/actualizar/factura/' + idx;
    return this.http.put(url, JSON.parse(JSON.stringify(factura)), {headers: header} )
                              .map( (resp: any) => {   swal("La actualización se ha completado", {
                                icon: "success",
                              }); } )
                              .catch( err => {
                                swal('Error', err.error.error.sqlMessage , 'error');
                                return Observable.throw( err )});
  }


  getDataFacturasParaDistribuidores(header: any) {
    const url: string = URL_SERVICIOS + '/registro/' + this.usuarioService.usuario[0].dealer_id;
    return this.http.get(url, {headers: header })
                    .map( ( data: any)  => { this.facturas = Object.values(data);
                                            this.totalRegistro = Object.keys(this.facturas[1]).length; })
                    .catch( err => { swal('Error', err.error.error , 'error');
                                     return Observable.throw( err ) });
  }

}
