import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { Motor } from '../../models/motor.model';
import swal from 'sweetalert';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class MotorService {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  constructor( private http: HttpClient) { }

  registrarMotor( motor: Motor) {

    const url: string = URL_SERVICIOS + '/engine/register';
    return this.http.post( url , JSON.parse(JSON.stringify(motor)), {headers: this.headers}).map( (resp: any) => { });
  }

  updateMotor( motor: Motor , idx: string) {

    const url: string = URL_SERVICIOS + '/actualizar/motor/' + idx;
    return this.http.put(url, JSON.parse(JSON.stringify(motor)), {headers: this.headers})
                            .map( (resp: any) => {
                              swal("La actualización se ha completado", {
                                icon: "success",
                              });
                            })
                            .catch( err => {
                              console.log( err.error.error.sqlMessage );
                              swal('Error', err.error.error.sqlMessage , 'error');
                              return Observable.throw( err )});
  }

}
