import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Distribuidor } from '../../../models/distribuidor.model';
import { DistribuidorService } from '../../../services/distribuidor/distribuidor.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import swal from 'sweetalert';


@Component({
  selector: 'app-crear-distribuidor',
  templateUrl: './crear-distribuidor.component.html',
  styleUrls: ['./crear-distribuidor.component.css']
})
export class CrearDistribuidorComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;

  constructor( public distribuidorService: DistribuidorService,
               private router: Router) { }

validaRut(rutCompleto: string) {

    return ( group: FormGroup) => {

      let rut_full = group.controls[rutCompleto].value;

      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rut_full ))  {
        return {
          validaRut: true
        }
      }

      let tmp 	= rut_full.split('-');
      let digv	= tmp[1];
      let rut 	= tmp[0];
  
      if ( digv == 'K' ) digv = 'k';

      //VALIDAR DIGITO
      let tmp_dv = this.validaDigitoDv(rut).toString();
      if( digv === tmp_dv) {
        console.log('valido');
        return null;
      }
        console.log('invalido');
        return {
          validaRut: true
        }
     }
   }

  validaDigitoDv(T: any) {
    var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';

  }

  ngOnInit() {
    this.forma = new FormGroup({
      nombre_empresa : new FormControl(null, Validators.required ),
      rut_empresa    : new FormControl( null, [ Validators.required]),
    }, { validators: this.validaRut('rut_empresa') } );
  }

  registrarDistribuidor() {

    if (!this.forma.valid) {
      return swal('Formulario con error en RUT, volver a revisar');
    }

    const distribuidor: Distribuidor = new Distribuidor(
      this.forma.value.nombre_empresa,
      this.forma.value.rut_empresa,
    );

    swal( {

      title: "Esta seguro de continuar?",
      text: "Seleccione alguna de las alternativas",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.distribuidorService.crearDistribuidor( distribuidor , this.headers)
                                .subscribe( resp => this.router.navigate(['/distribuidores']));
        swal("El Distribuidor se ha registrado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }



}
