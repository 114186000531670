import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class MecGuard implements CanActivate  {

  constructor( public usuarioService: UsuarioService, public router: Router) {}

  canActivate() {
    if(this.usuarioService.usuario[0].rol === 'MEC' || this.usuarioService.usuario[0].rol === 'ADMIN' ) {
      // console.log("paso el guard de MecGuard");
      return true;
    } else {
      this.router.navigate(['/factura']);
      // console.log("ir a facturas MecGuard, fallo");
      return false;
    }
 }

}