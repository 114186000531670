import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mostrar-usuario',
  templateUrl: './mostrar-usuario.component.html',
  styleUrls: ['./mostrar-usuario.component.css']
})
export class MostrarUsuarioComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));


  pageActual: number = 1;
  public usuarios = [];
  public usuariosDesactivados = [];

  public totalRegistro;
  public id;

  //LOADING
  loading: boolean = true;
  
  constructor( private http: HttpClient,
               public usuarioService: UsuarioService,
               private router: Router) { }

  ngOnInit() {
    this.getDataUsuarios();
    this.id = this.usuarioService.usuario[0].id;
  }

  getDataUsuarios() {
    const url: string = URL_SERVICIOS + '/usuarios';
    this.usuarios = [];
    this.http.get(url, { headers: this.headers}).subscribe( ( data: any)  => {
      this.usuarios = Object.values(data);
      this.totalRegistro = Object.keys(this.usuarios[1]).length;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }


  Desactivar( id: any ) {
    
    this.getDataUsuarios();

    let turn_on = ({
      condicion: 1,
    });
    
    swal( {
      title: "Esta seguro de continuar?",
      text:  "Se va a habilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
          const url: string = URL_SERVICIOS + '/activador/usuario/' + id;
            this.http.put(url, turn_on, {headers: this.headers}).subscribe( ( data: any)  => {
              this.usuarios  = Object.values(data);
              this.getDataUsuarios();
            });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar/usuario/', termino]);
  }


}
