import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { URL_SERVICIOS } from '../../config/config';
import swal from 'sweetalert';

@Component({
  selector: 'app-busqueda-usuarios',
  templateUrl: './busqueda-usuarios.component.html',
  styleUrls: ['./busqueda-usuarios.component.css']
})
export class BusquedaUsuariosComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;
  pageActual: number = 1;
  public usuarios = [];
  public usuariosDesactivados = [];
  public totalRegistro;
  public termino;
  public id;

  //LOADING
  loading: boolean = true;

  constructor( public activatedRoute: ActivatedRoute,
               public usuarioService: UsuarioService,
               private http: HttpClient,
               private router: Router) {
                activatedRoute.params
                .subscribe( params => {
                  this.termino = params['termino'];
                  if (this.termino === '') {
                    this.router.navigate(['/usuarios']);
                  }
                  this.getDataBuscar(this.termino);
                });
   }

  ngOnInit() {
    this.id = this.usuarioService.usuario[0].id;
  }

  getDataBuscar( termino: string ){
    let url = URL_SERVICIOS + '/usuario/buscar/'+ termino;
    this.usuarios = [];
    this.http.get(url, {headers: this.headers})
             .subscribe( ( data: any ) => {
                this.usuarios = Object.values(data);
                this.totalRegistro = Object.keys(this.usuarios[1]).length;
                setTimeout(() => {
                  this.loading = false;
                }, 500);
             });
  }

  getDataUsuarios() {
    const url: string = URL_SERVICIOS + '/usuarios';
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.usuarios = Object.values(data);
      this.totalRegistro = Object.keys(this.usuarios[1]).length;
    });
  }

  Desactivar( id: any ) {
    
    this.getDataBuscar(this.termino);

    let turn_on = ({
      condicion: 1,
    });
    
    swal( {
      title: "Esta seguro de continuar?",
      text:  "Se va a habilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
          const url: string = URL_SERVICIOS + '/activador/usuario/' + id;
            this.http.put(url, turn_on, {headers: this.headers}).subscribe( ( data: any)  => {
              this.usuarios  = Object.values(data);
              this.getDataBuscar(this.termino);
            });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar/usuario', termino]);
  }
  
}
