import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../../config/config';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert';
import { UsuarioService } from '../../../services/usuario/usuario.service';


@Component({
  selector: 'app-actualizar-usuario',
  templateUrl: './actualizar-usuario.component.html',
  styleUrls: ['./actualizar-usuario.component.css']
})
export class ActualizarUsuarioComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;
  public dealer = [];
  public usuarioAux = [];
  public usuario  = [];
  public param: string;
  public user: any[];

  constructor( private activateRoute: ActivatedRoute,
               private http: HttpClient,
               public usuarioService: UsuarioService
               ) {

    this.activateRoute.params.subscribe( params => {
      this.param = params['id'];
   });

  }

  sonIguales( campo1: string, campo2: string ){

    return (group: FormGroup) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if(pass1 === pass2) {
        return null;
      }
      return {
          sonIguales: true
      };
    };
  }

  ngOnInit() {

    this.getDistribuidores();
    this.getUsuario(this.param);

    this.forma = new FormGroup({
      usuario:   new FormControl( null, Validators.required ),
      email:     new FormControl( null, [ Validators.required , Validators.email]),
      password:  new FormControl( null, Validators.required ),
      password2: new FormControl( null, Validators.required ),
      dealer:    new FormControl(Validators.required),
      rol:       new FormControl(Validators.required),
    },  { validators: this.sonIguales( 'password', 'password2') });
  }

  getDistribuidores() {
    const url = URL_SERVICIOS + '/distribuidor';
    this.http.get(url, { headers: this.headers})
    .subscribe( ( data: any)  => {
      this.dealer = Object.values(data);
      // console.log(this.dealer);
    });
  }

  getUsuario(idx: string) {
    const url: string = URL_SERVICIOS + '/usuario/' + idx;

    this.http.get(url, { headers: this.headers} ).subscribe( ( data: any)  => {
      this.usuarioAux = Object.values(data);
      this.usuario = this.usuarioAux[1][0];

      this.user = Object.values(this.usuario);
      // console.log(this.user);

      this.forma.setValue ({
        usuario  : this.user[2],
        email    : this.user[3],
        password : '',
        password2: '',
        dealer   : this.user[7],
        rol      : this.user[1]
      });
    });

  }

  actualizarUsuario() {
    let _user_sinpass = ({
       rol       : this.forma.value.rol,
       usuario   : this.forma.value.usuario,
       email     : this.forma.value.email.trim(),
       dealer_id : this.forma.value.dealer
    });

    let _user = ({
      rol       : this.forma.value.rol,
      usuario   : this.forma.value.usuario,
      email     : this.forma.value.email,
      dealer_id : this.forma.value.dealer,
      password  : this.forma.value.password
   });

    // console.log('pass1:' + this.forma.value.password  + 'pass2:' + this.forma.value.password2);


    if( this.forma.value.password == '') {
        this.actualizarUserSinPass(_user_sinpass);
    }
    else if (this.forma.value.password != this.forma.value.password2) {
      alert('Las password tienen que ser iguales ');
    } else {
      this.actualizarUsuarioYPass(_user);
    }
  }

  // CON PASS //
  private actualizarUsuarioYPass(_user: { rol: any; usuario: any; email: any; dealer_id: any; password: any; }) {
    swal({
      title: "Esta seguro que desea continuar?",
      text: "Se va actualizar el registro del Usuario y su contraseña",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
        if (willDelete) {
          this.usuarioService.updateUsuarioConPass(_user, this.param, this.headers).subscribe((data: any) => {});
        }
        else {
          swal("OK, todo esta bien ");
        }
      });
  }

  // SIN PASS //
  private actualizarUserSinPass(_user_sinpass: { rol: any; usuario: any; email: any; dealer_id: any; }) {
    swal({
      title: "Esta seguro que desea continuar?",
      text: "Se va actualizar el registro del Usuario",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
        if (willDelete) {
          this.usuarioService.updateUsuarioSinPass(_user_sinpass, this.param, this.headers).subscribe((data: any) => {});
        }
        else {
          swal("OK, todo esta bien ");
        }
      });
  }

}
