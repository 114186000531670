import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';


@Component({
  selector: 'app-mostrar-factura-desactivadas',
  templateUrl: './mostrar-factura-desactivadas.component.html',
  styleUrls: ['./mostrar-factura-desactivadas.component.css']
})
export class MostrarFacturaDesactivadasComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;
  public facturasDesactivadas;
  public totalRegistro;
  public facturas = [];

  constructor( private  http: HttpClient ) { }

  ngOnInit() {
    this.getFacturas();
  }

  getFacturas() {
    const url: string = URL_SERVICIOS + '/desactivados';
    this.facturas = [];
    this.http.get(url, { headers: this.headers}).subscribe( ( data: any)  => {
      this.facturas = Object.values(data);
      this.totalRegistro = Object.keys(this.facturas[1]).length;
    });
  }

  Activar( id: any ) {
   this.getFacturas();
   let turn_off = ({ 
        condicion: 0,
      });
    swal( {
        title: "Esta seguro de continuar?",
        text:  "Se va a habilitar este registro",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      } as any)
        .then((willDelete) => {
        if (willDelete) {
            const url: string = URL_SERVICIOS + '/activador/factura/distribuidor/' + id;
              this.http.put(url, turn_off, { headers: this.headers}).subscribe( ( data: any)  => {
                this.facturasDesactivadas  = Object.values(data);
                this.getFacturas();
              });
          swal("La acción se ha completado ...", {
            icon: "success",
          });
        } else {
          swal("OK, vamos a esperar su respuesta");
        }
      });
  }
}
