

export class Usuario {

  constructor (
    public usuario: String,
    public email: String,
    public password: String,
    public dealer: String,
    public rol?: String,
    public usuario_id ?: String
  ) {

  }


}