import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import swal from 'sweetalert';
import { Motor } from 'src/app/models/motor.model';
import { MotorService } from '../../../services/motor/motor.service';

@Component({
  selector: 'app-crear-motor',
  templateUrl: './crear-motor.component.html',
  styleUrls: ['./crear-motor.component.css']
})
export class CrearMotorComponent implements OnInit {
  
  forma: FormGroup;
  public param: string;
  constructor( public motorService: MotorService,
               private router: Router ) { }

  ngOnInit() {
    this.forma = new FormGroup({
      motor   : new FormControl(null, Validators.required ),
      time_id : new FormControl( null, Validators.required),
    });
  }

  registrarMotor() {

    if (!this.forma.valid) {
      return swal('Formulario con error en RUT, volver a revisar');
    }
    
    const motor: Motor = new Motor(
      this.forma.value.motor.trim(),
      this.forma.value.time_id,
    );

    swal( {

      title: "Esta seguro de continuar?",
      text: "Seleccione alguna de las alternativas",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.motorService.registrarMotor( motor )
                                .subscribe( resp => this.router.navigate(['/motores']));
        swal("El motor se ha registrado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });

  }
}
