import { Injectable } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Router } from '@angular/router';

import swal from 'sweetalert';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';


@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  usuario: Usuario;
  token: string;

  constructor( public http: HttpClient, public router: Router) {
      this.cargarStorage();
   }

   estaLogeado() {
     return ( this.token.length > 5)  ? true : false;
   }

   cargarStorage() {
      if ( localStorage.getItem('token') ) {
        this.token = localStorage.getItem('token');
        this.usuario = JSON.parse(localStorage.getItem('usuario'));
      } else {
        this.token = '';
        this.usuario = null;
      }
   }

   guardarLocalStorage(token: string, usuario: Usuario) {

      localStorage.setItem('token', token );
      localStorage.setItem('usuario', JSON.stringify(usuario));
      this.usuario = usuario;
      this.token   = token;
    }



    logout() {
      this.usuario = null;
      this.token = ' ';

      localStorage.removeItem('token');
      localStorage.removeItem('usuario');

      this.router.navigate(['/login']);
    }


    login( usuario: Usuario, recordar: Boolean = false)  {

      if (recordar) {
          localStorage.setItem('email', usuario.email.toString());
      } else {
        localStorage.removeItem('email');
      }

      //URL BACKEND
      let url  = URL_SERVICIOS + '/login';
      return this.http.post( url, usuario)
                      .map( (resp:any) => {
                        this.guardarLocalStorage(resp.token, resp.usuario);
                      })
                      .catch( err => {
                        swal('Error en el Login', err.error.error, 'error');
                        return Observable.throw( err );
                      });

   }

   crearUsuario( usuario: Usuario, header: any) {

      let url  = URL_SERVICIOS + '/user/register';
      return this.http.post( url , JSON.parse(JSON.stringify(usuario)) , { headers: header } )
                      .map( (resp: any) => {
                        swal('EL usuario:   ', usuario.email.toString() + '   fue creado con éxito', 'success');
                        return resp.usuario;
                      }).catch( err => {
                        swal('Error', err.error.error.sqlMessage , 'error');
                        return Observable.throw( err );
                      });
   }


   updateUsuarioConPass( usuario: any, idx: string, header: any) {

    const url: string = URL_SERVICIOS + '/actualizar/usuario/password/' + idx;
    return this.http.put( url, JSON.parse(JSON.stringify(usuario)), { headers: header } )
                    .map( (resp: any) => {
                      swal("La actualización se ha completado", {
                        icon: "success",
                      })
                    })
                    .catch( err => {
                      swal('Error', err.error.error.sqlMessage , 'error');
                      return Observable.throw( err );
                    });
 }


 updateUsuarioSinPass( usuario: any, idx: string, header: any) {

  const url_sin_pass: string = URL_SERVICIOS + '/actualizar/usuario/' + idx;
  // console.log(header);
  return this.http.put( url_sin_pass, usuario, { headers: header } )
                  .map( (resp: any) => {
                    swal("La actualización se ha completado", {
                      icon: "success",
                    })
                  })
                  .catch( err => {
                    swal('Error', err.error.error, 'error');
                    return Observable.throw( err );
                  });
  }

}
