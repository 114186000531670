import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../../../../services/usuario/usuario.service';
import { Usuario } from '../../../../models/usuario.model';

import { Router } from '@angular/router';

import swal from 'sweetalert';
import { URL_SERVICIOS } from '../../../../config/config';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;
  public dealer = [];


  constructor(
    private http: HttpClient,
    public _usuarioServices: UsuarioService,
    public router: Router
  ) { }


  sonIguales( campo1:string, campo2:string ) {

    return (group: FormGroup) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if( pass1 === pass2) {
        return null;
      }
      return {
          sonIguales: true
      };
    };
  }

  ngOnInit() {
    this.forma = new FormGroup({

      usuario:   new FormControl( null, Validators.required ),
      email:     new FormControl( null, [ Validators.required , Validators.email]),
      password:  new FormControl( null, Validators.required ),
      password2: new FormControl( null, Validators.required ),
      dealer:    new FormControl(Validators.required),
      rol:       new FormControl(Validators.required),

    }, { validators: this.sonIguales( 'password', 'password2') } );

    this.getDistribuidores();

    this.forma.setValue({
      usuario: '',
      email: '',
      password: '',
      password2: '',
      dealer: 1,
      rol: 'DISTRIBUIDOR'
    });
  }

  registrarUsuario() {

    if (this.forma.invalid) {
      return;
    }

    const usuario: Usuario = new Usuario(
      this.forma.value.usuario,
      this.forma.value.email.trim(),
      this.forma.value.password,
      this.forma.value.dealer,
      this.forma.value.rol
    );

    swal( {

      title: "Esta seguro que quiere crear al usuario: " + usuario.usuario + ", con  ROl: " + usuario.rol ,
      text: "Seleccione alguna de las alternativas",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this._usuarioServices.crearUsuario( usuario , this.headers )
                             .subscribe( resp => this.router.navigate(['/login']));
      } else {
        swal("OK, vamos a esperar su respuesta para después");
      }
    });
  }

  getDistribuidores() {
    const url_dealers = URL_SERVICIOS + '/distribuidor';
    this.http.get(url_dealers, { headers: this.headers})
    .subscribe( ( data: any)  => {
      this.dealer = Object.values(data);
    });
  }

}
