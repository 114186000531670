import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../../config/config';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Motor } from '../../../models/motor.model';
import { MotorService } from '../../../services/motor/motor.service';

import swal from 'sweetalert';

@Component({
  selector: 'app-actualizar-motor',
  templateUrl: './actualizar-motor.component.html',
  styleUrls: ['./actualizar-motor.component.css']
})
export class ActualizarMotorComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  public motorAux = [];
  public motor  = [];
  public param: string;

  public engine: any[];

  forma: FormGroup;


  constructor( private activateRoute: ActivatedRoute,
               private http: HttpClient,
               public motorService: MotorService
               ) {

    this.activateRoute.params.subscribe( params => {
       this.param = params['id'];
    });

  }

  ngOnInit() {

    this.getMotor(this.param);

    this.forma = new FormGroup({
      modelo  :   new FormControl(null, Validators.required ),
      time_id :   new FormControl( null, Validators.required),
    });

  }

  getMotor(idx: string) {
    const url: string = URL_SERVICIOS + '/motor/' + idx;

    this.http.get(url , {headers: this.headers}).subscribe( ( data: any)  => {

      this.motorAux = Object.values(data);
      this.motor = this.motorAux[1][0];
      this.engine = Object.values(this.motor);

      this.forma.setValue({
        modelo  : this.engine[1],
        time_id : this.engine[2]
      });
    });
  }

  actualizarMotor( ) {
    let _motor = new Motor(
      this.forma.value.modelo.trim(),
      this.forma.value.time_id,
  );

  swal( {
      title: "Esta seguro que desea continuar?",
      text: "Se va actualizar el registro del Motor",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
              this.motorService.updateMotor(_motor, this.param).subscribe( ( data: any)  => {});
      } else {
        swal("OK, todo esta bien ");
      }
    });
  }
}

