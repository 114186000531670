import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor( 
    public _usuarioService: UsuarioService,
    public router: Router
   ) {}

  canActivate() {
    if ( this._usuarioService.usuario[0].rol === 'ADMIN') {
        return true;
    } else {
      this._usuarioService.logout();
    }


  }
}
