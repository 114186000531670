import { Component, OnInit } from '@angular/core';
import { FacturaService } from '../../services/factura/factura.service';
import { HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styles: []
})
export class RegistrosComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'))
                             .set('Access-Control-Allow-Origin', "*")

  forma: FormGroup;

  public facturas = [];
  public totalRegistro;
  pageActual: number = 1;

  //LOADING
  loading: boolean = true;

  constructor( public activatedRoute: ActivatedRoute,
               public facturaService: FacturaService,
               public usuarioService: UsuarioService,
               private router: Router ) { }

  ngOnInit() {

    this.getDataFacturas();

  }

  getDataFacturas() {

    this.facturaService.getDataFacturasParaDistribuidores(this.headers).subscribe( ( data: any)  => {  
        this.facturas      = this.facturaService.facturas;
        this.totalRegistro = this.facturaService.totalRegistro;
        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar/venta', termino]);
  }

}





