
export class Factura {

    constructor(
      public factura: String,
      public fecha_venta: String,
      public fecha_entrega: String,
      public nombre_cliente: String,
      public apellido_cliente: String,
      public rut_cliente: String,
      public serie: String,
      public motor: String,
      public comuna: String,
      public marca_helice: String,
      public paso_helice: String,
      public material_helice: String,
      public id:String,
      public created_at:String,
      public direccion?: String,
      public telefono?: String,
      public email?: String,
      public usabilidad?: String,
      public lugar_uso?: String,
      public marca_bote?: String,
      public modelo_bote?: String,
      public slora?: String,
      public manga?: String,
      public material_bote?: String,
      public comentario?: String,
      public update_at?:String,
      public otro_uso?:String,
      public filtro_agua?:String,
      public marca_filtro?:String
    ) { }
}