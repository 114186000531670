import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { UsuarioService } from '../../../services/usuario/usuario.service';


@Component({
  selector: 'app-mostrar-factura',
  templateUrl: './mostrar-factura.component.html',
  styleUrls: ['./mostrar-factura.component.css']
})
export class MostrarFacturaComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;

  public facturas = [];
  public facturasDesactivadas = [];
  
  public totalRegistro;
  public rol;

  //LOADING
  loading: boolean = true;

  constructor( private http:HttpClient,
               private router: Router,
               public userService: UsuarioService ) { }

  ngOnInit() {
    this.getDataFacturas();
    this.rol = this.userService.usuario[0].rol;
  }

  getDataFacturas() {
    const url: string = URL_SERVICIOS + '/registro';
    this.facturas = [];
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.facturas      = Object.values(data);
      this.totalRegistro = Object.keys(this.facturas[1]).length;
      setTimeout(() => {
        this.loading = false;
      }, 300);
    });
  }

  Desactivar( id: any ) {
    let turn_off = ({
      condicion: 1,
    });

    const url: string = URL_SERVICIOS + '/activador/factura/distribuidor/' + id;
    // this.getDataFacturas();

    swal( {

      title: "Esta seguro de continuar?",
      text:  "Se va a deshabilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.http.put(url, turn_off, {headers: this.headers}).subscribe( ( data: any)  => {
                      this.facturasDesactivadas  = Object.values(data);
                      this.getDataFacturas();
                    });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar', termino]);
  }

}
