import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import swal from 'sweetalert';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../../../services/usuario/usuario.service';


@Component({
  selector: 'app-mostrar-usuario-desactivado',
  templateUrl: './mostrar-usuario-desactivado.component.html',
  styleUrls: ['./mostrar-usuario-desactivado.component.css']
})
export class MostrarUsuarioDesactivadoComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;
  public usuarios = [];
  public totalRegistro;
  constructor( private http: HttpClient,
               public usuarioService: UsuarioService) { }

  ngOnInit() {
    this.getDataUsuarios();
  }

  getDataUsuarios() {
    const url: string = URL_SERVICIOS + '/usuarios/desactivados';
    this.usuarios = [];
    // console.log(this.usuarios);
    
    this.http.get(url, { headers: this.headers} ).subscribe( ( data: any)  => {
      this.usuarios = Object.values(data);
      this.totalRegistro = Object.keys(this.usuarios[1]).length;
    });
  }


  Activar( id: any ) {
    this.getDataUsuarios();

    let turn_on = ({
      condicion: 0,
    });
    
    swal( {
      title: "Esta seguro de continuar?",
      text:  "Se va a habilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
          const url: string = URL_SERVICIOS + '/activador/usuario/' + id;
            this.http.put(url, turn_on, {headers: this.headers}).subscribe( ( data: any)  => {
              this.usuarios  = Object.values(data);
              this.getDataUsuarios();
            });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }
}
