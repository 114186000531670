import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from '../../../config/config';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';


@Component({
  selector: 'app-mostrar-motor',
  templateUrl: './mostrar-motor.component.html',
  styleUrls: ['./mostrar-motor.component.css']
})
export class MostrarMotorComponent implements OnInit {


  headers = new HttpHeaders().set('token', localStorage.getItem('token'));


  pageActual: number = 1;
  public motores = [];
  public totalRegistro;

  public facturasDesactivadas = [];

  //LOADING
  loading: boolean = true;

  constructor( public usuarioService: UsuarioService, private http: HttpClient) { }

  ngOnInit() {
    this.getDataMotores();
  }

  getDataMotores() {
    const url: string = URL_SERVICIOS + '/motor';
    this.motores = [];
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.motores = Object.values(data);
      this.totalRegistro = Object.keys(this.motores[1]).length;
      setTimeout(() => {
        this.loading = false;
      }, 300);
    });
  }

  Desactivar( id: any ) {
    let turn_off = ({
      condicion: 1,
    });
    const url: string = URL_SERVICIOS + '/activador/motor/' + id;
    this.getDataMotores();

    swal( {

      title: "Esta seguro de continuar?",
      text:  "Se va a deshabilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.http.put(url, turn_off , {headers: this.headers}).subscribe( ( data: any)  => {
                      // this.comunas = data;
                      this.facturasDesactivadas  = Object.values(data);
                      this.getDataMotores();
                    });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }
}
