import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { FacturaComponent } from './pages/factura/crear-factura/factura.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { RegisterComponent } from './pages/usuario/crear-usuario/register/register.component';
import { RegistrosComponent } from './pages/registros/registros.component';
import { MostrarComponent } from './pages/registros/mostrar/mostrar.component';

import { CrearDistribuidorComponent } from './pages/distribuidores/crear-distribuidor/crear-distribuidor.component';
import { MostrarMotorComponent } from './pages/motor/mostrar-motor/mostrar-motor.component';
import { ActualizarMotorComponent } from './pages/motor/actualizar-motor/actualizar-motor.component';
import { CrearMotorComponent } from './pages/motor/crear-motor/crear-motor.component';
import { MostrarFacturaComponent } from './pages/factura/mostrar-factura/mostrar-factura.component';
import { ActualizarFacturaComponent } from './pages/factura/actualizar-factura/actualizar-factura.component';
import { MostrarUsuarioComponent } from './pages/usuario/mostrar-usuario/mostrar-usuario.component';
import { ActualizarUsuarioComponent } from './pages/usuario/actualizar-usuario/actualizar-usuario.component';
import { MostrarDistribuidorComponent } from './pages/distribuidores/mostrar-distribuidor/mostrar-distribuidor.component';
import { ActualizarDistribuidorComponent } from './pages/distribuidores/actualizar-distribuidor/actualizar-distribuidor.component';
import { MostrarFacturaDesactivadasComponent } from './pages/factura/mostrar-factura-desactivadas/mostrar-factura-desactivadas.component';
import { BusquedaComponent } from './pages/busqueda/busqueda.component';
import { DistribuidorGuard } from './services/guards/distribuidor.guard';

import { AdminGuard } from './services/guards/admin.guard';
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { MostrarMotorDesactivadoComponent } from './pages/motor/mostrar-motor-desactivado/mostrar-motor-desactivado.component';
import { MostrarUsuarioDesactivadoComponent } from './pages/usuario/mostrar-usuario-desactivado/mostrar-usuario-desactivado.component';
import { BusquedaDistribuidorComponent } from './pages/busqueda-distribuidor/busqueda-distribuidor.component';
import { MecGuard } from './services/guards/mec.guard';
import { MostrarDistribuidorDesactivadoComponent } from './pages/distribuidores/mostrar-distribuidor-desactivado/mostrar-distribuidor-desactivado.component';
import { BusquedaUsuariosComponent } from './pages/busqueda-usuarios/busqueda-usuarios.component';

const appRoute: Routes = [
    
  {path: 'login', component: LoginComponent},

 {   path: '',
     component: PagesComponent,
     canActivate: [ LoginGuardGuard ],
     children: [

      //EN COMUN ROLES
       {path: 'registros/mostrar/:id', component: MostrarComponent},
       
       //Mostrar Registros por distribuidor
       {path: 'registros', component: RegistrosComponent , canActivate:[DistribuidorGuard]},
       {path: 'factura', component: FacturaComponent, canActivate:[DistribuidorGuard]},
       
       // Buscar facturas distribuidor
       {path: 'buscar/venta/:termino', component: BusquedaDistribuidorComponent , canActivate:[DistribuidorGuard]},
       
       // GRAFICOS MEC Y ADMIN
       {path: 'dashboard', component: DashboardComponent , canActivate: [MecGuard] },
       {path: 'buscar/:termino', component: BusquedaComponent , canActivate: [MecGuard]},
       {path: 'facturas', component: MostrarFacturaComponent, canActivate: [MecGuard] },
       
       // BUSCAR admin
       {path: 'buscar/usuario/:termino', component: BusquedaUsuariosComponent , canActivate: [AdminGuard]},
       
       //facturas 
       {path: 'facturas/mostrar/:id', component: ActualizarFacturaComponent , canActivate: [AdminGuard] },
       
       // FACTURAS ADMIN
       {path: 'facturas/desactivadas', component: MostrarFacturaDesactivadasComponent, canActivate: [AdminGuard]},
       
       
       // USUARIOS
       {path: 'usuarios', component: MostrarUsuarioComponent , canActivate: [AdminGuard] },
       {path: 'usuarios/desactivadas', component: MostrarUsuarioDesactivadoComponent, canActivate: [AdminGuard]},
       {path: 'usuarios/mostrar/:id', component: ActualizarUsuarioComponent , canActivate: [AdminGuard] },
       {path: 'usuarios/registrar', component: RegisterComponent , canActivate: [AdminGuard]},

       // DISTRIBUIDORES
       {path: 'distribuidores', component: MostrarDistribuidorComponent , canActivate: [AdminGuard]},
       {path: 'distribuidores/desactivados', component: MostrarDistribuidorDesactivadoComponent, canActivate: [AdminGuard]},
       {path: 'distribuidores/registrar', component: CrearDistribuidorComponent , canActivate: [AdminGuard]},
       {path: 'distribuidores/mostrar/:id', component: ActualizarDistribuidorComponent, canActivate: [AdminGuard]},
      
       
       // MOTORES
       {path: 'motores', component: MostrarMotorComponent , canActivate: [AdminGuard]},
       {path: 'motores/desactivadas', component: MostrarMotorDesactivadoComponent, canActivate: [AdminGuard]},
       {path: 'motores/registrar', component: CrearMotorComponent , canActivate: [AdminGuard]},
       {path: 'motores/mostrar/:id', component: ActualizarMotorComponent , canActivate: [AdminGuard]},

       {path: '', redirectTo: '/dashboard' , pathMatch: 'full' },
     ]
 },

 {path: '404', component: NopagefoundComponent },
 {path: '**', component: NopagefoundComponent },

];

export const APP_ROUTES = RouterModule.forRoot( appRoute, {useHash:true} );