import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import swal from 'sweetalert';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';


@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styles: []
})
export class BusquedaComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;
  facturas: any = [];

  public totalRegistro;
  public termino;
  public facturasDesactivadas = [];
  public rol;

  //LOADING
  loading: boolean = true;


  constructor(public activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private router: Router,
              private userService: UsuarioService) {
  activatedRoute.params
                  .subscribe( params => {
                    this.termino = params['termino'];
                    if (this.termino === '') {
                      this.router.navigate(['/facturas']);
                    }
                    this.getDataBuscar(this.termino);
                  });
  }

  ngOnInit() {
    this.rol = this.userService.usuario[0].rol;
  }


  getDataBuscar( termino: string ){
    let url = URL_SERVICIOS + '/factura/buscar/' + termino;
    this.facturas = [];
    this.http.get(url, {headers: this.headers} )
             .subscribe( ( data: any ) => {
                this.facturas = Object.values(data);
                this.totalRegistro = Object.keys(this.facturas[1]).length;
                setTimeout(() => {
                  this.loading = false;
                }, 500);
             });
  }


  Desactivar( id: any ) {
    let turn_off = ({
      condicion: 1,
    });

    const url: string = URL_SERVICIOS + '/activador/factura/distribuidor/' + id;

    swal( {
      title: "Esta seguro de continuar?",
      text:  "Se va a deshabilitar este registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.http.put(url, turn_off, {headers: this.headers}).subscribe( ( data: any)  => {
                      this.facturasDesactivadas  = Object.values(data);
                      console.log(this.facturasDesactivadas);
                      this.getDataBuscar(this.termino);
                    });
        swal("La acción se ha completado ...", {
          icon: "success",
        });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar', termino]);
  }

}
