import { Component, OnInit } from '@angular/core';
import { ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));


  public chartOption = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }

  // public empresas: any = ['COMERCIAL RTI SPA', 'NICHIMAR DE COMERCIO LTDA'];
  public dealers = [];
  public data = [];
  public data_dealers = [];
  public barChartLegend = true;
  public barChartType: ChartType = 'bar';

  //GENERAL 
  public barChartLabel: Label[] = [];
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'MOTORES'},
  ];

  //EMPRESAS  
  public barChartLabelDealers: Label[] = [];
  public barChartDataDealers: ChartDataSets[] = [
    { data: [], label: 'MOTORES'},
  ];

  public barChartColors: Color[] = [
    { backgroundColor: 'blue' },
  ]


  constructor( private http: HttpClient) { }

  ngOnInit() {
    this.getGraficaMotores();
    this.getEmpresa();
  }

  getGraficaMotores() {
    const url_motor = URL_SERVICIOS + '/contar/motores';
    this.http.get(url_motor, {headers: this.headers})
    .subscribe( ( data: any)  => {
      this.data = Object.values(data);
      this.VolcarDatos(this.data);
      // console.log(this.data);
    });
  }

  getEmpresa() {
    const url_dealer = URL_SERVICIOS + '/distribuidor';
    this.http.get(url_dealer , {headers: this.headers})
    .subscribe( ( data: any)  => {
      this.dealers = Object.values(data);
      this.dealers[1].splice(0, 1);
      // console.log(this.dealers);
    });
  }

  VolcarDatos(data) {
    let data_empresa = [];

    Object.keys(data[1]).forEach( e => {
      // console.log(data[1][e].nombre_empresa);
      this.barChartLabel.push(data[1][e].modelo);
      this.barChartData[0].data.push(data[1][e].total);
      data_empresa.push(data[1][e].modelo);
    });
  }

  VolcarDatosDelears(data) {
    let data_empresa = [];
    Object.keys(data[1]).forEach( e => {
      this.barChartLabelDealers.push(data[1][e].modelo);
      this.barChartDataDealers[0].data.push(data[1][e].total);
      data_empresa.push(data[1][e].modelo);
    });
  }

  graficar(value: string ) {
    // console.log("valor:"+ value);
    this.barChartLabelDealers = [];
    this.barChartDataDealers = [
      { data: [], label: 'MOTORES'},
    ];
    const url_dealers_motor = URL_SERVICIOS + '/contar/motores/' + value;
    this.http.get(url_dealers_motor, {headers: this.headers})
    .subscribe( ( data: any)  => {
      this.data_dealers = Object.values(data);
      this.VolcarDatosDelears(this.data_dealers);
    });
  }

}
