import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../../config/config';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Factura } from '../../../models/factura.model';
import { FacturaService } from '../../../services/factura/factura.service';

import swal from 'sweetalert';

const url_comunas  = URL_SERVICIOS + '/comunas';
const url_motores  = URL_SERVICIOS + '/motor';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css']
})

export class FacturaComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;

  //Inicializar datos
  public comunas = [];
  public motores = [];
  public distribuidor = [];

  constructor(
    private http: HttpClient,
    public _usuarioServices: UsuarioService,
    public router: Router,
    public facturaService: FacturaService
  ) { }

  validaRut(rutCompleto: string) {

    return ( group: FormGroup) => {

      let rut_full = group.controls[rutCompleto].value;

      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rut_full ))  {
        return {
          validaRut: true
        }
      }

      let tmp 	= rut_full.split('-');
      let digv	= tmp[1];
      let rut 	= tmp[0];
  
      if ( digv == 'K' ) digv = 'k';

      //VALIDAR DIGITO
      let tmp_dv = this.validaDigitoDv(rut).toString();
      if( digv === tmp_dv) {
        // console.log('valido');
        return null;
      }
        // console.log('invalido');
        return {
          validaRut: true
        }
     }
   }

  validaDigitoDv(T: any) {
    var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';

  }

  ngOnInit() {
    this.getDataComunas();
    this.getDataMotores();
    this.getDistribuidor();

    this.forma = new FormGroup({
      factura         : new FormControl( Validators.required),
      fecha_venta     : new FormControl( Validators.required),
      nombre_cliente  : new FormControl( Validators.required),
      apellido_cliente: new FormControl( Validators.required),
      rut_cliente     : new FormControl(Validators.required),
      direccion       : new FormControl(),
      telefono        : new FormControl(),
      email           : new FormControl(Validators.email),
      serie           : new FormControl(Validators.required),
      usabilidad      : new FormControl(Validators.required),
      lugar_uso       : new FormControl(),
      paso_helice     : new FormControl(Validators.required),
      marca_helice    : new FormControl(Validators.required),
      material_helice : new FormControl(),
      marca_bote      : new FormControl(),
      modelo_bote     : new FormControl(),
      slora           : new FormControl(),
      manga           : new FormControl(),
      material_bote   : new FormControl(),
      motor           : new FormControl(Validators.required),
      fecha_entrega   : new FormControl(Validators.required),
      comuna          : new FormControl(Validators.required),
      comentario      : new FormControl(),
      otro_uso        : new FormControl(),
      filtro_agua     : new FormControl(),
      marca_filtro    : new FormControl()

    }, { validators: this.validaRut('rut_cliente') } );

    this.limpiarFormulario();
    console.log('valido:'+this.forma.valid);


  }

  getDataComunas() {
    this.http.get(url_comunas, {headers: this.headers})
      .subscribe( ( data: any)  => {
        this.comunas = Object.values(data);
      });
  }

  getDataMotores() {
    this.http.get(url_motores, {headers: this.headers})
      .subscribe( ( data: any)  => {
        this.motores = Object.values(data);
      });
  }

  getDistribuidor() {
    const url_distribuidor: string = URL_SERVICIOS +'/distribuidor/'+ this._usuarioServices.usuario[0].dealer_id;
    let distri_aux = [];
    this.http.get(url_distribuidor, {headers: this.headers})
             .subscribe( ( data: any)  => {
             distri_aux = Object.values(data);
             this.distribuidor = distri_aux[1][0];
    });
  }

  limpiarFormulario() {
    this.forma.setValue({
      factura         : '',
      fecha_venta     : '',
      nombre_cliente  : '',
      apellido_cliente: '',
      rut_cliente     : '',
      direccion       : '',
      telefono        : '',
      email           : '',
      serie           : '',
      usabilidad      : '',
      lugar_uso       : '',
      paso_helice     : '',
      marca_helice    : '',
      material_helice : '',
      marca_bote      : '',
      modelo_bote     : '',
      slora           : '',
      manga           : '',
      material_bote   : '',
      motor           : '',
      fecha_entrega   : '',
      comuna          : '',
      comentario      : '',
      otro_uso        : '',
      filtro_agua     : '',
      marca_filtro    : ''
    });
  }

  registrarFactura() {
    if (!this.forma.valid) {
      return swal('Error', 'Formulario invalido' , 'error');
    }

    // console.log('valido:'+this.forma.valid);
    if(this.forma.value.filtro_agua == "") {
      this.forma.value.filtro_agua = 2;
    }

    if(this.forma.value.filtro_agua == 0 ) {
      this.forma.value.marca_filtro = '';
    }

    const hoy = new Date();
    let fecha: string = hoy.getFullYear()+'/'+(hoy.getMonth() + 1)+'/'+hoy.getDate();  
    //INCIALIZAR DATOS CON MODELO:
    let factura = new Factura(
            this.forma.value.factura,
            this.forma.value.fecha_venta,
            this.forma.value.fecha_entrega,
            this.forma.value.nombre_cliente,
            this.forma.value.apellido_cliente,
            this.forma.value.rut_cliente,
            this.forma.value.serie.trim(),
            this.forma.value.motor,
            this.forma.value.comuna,
            this.forma.value.marca_helice,
            this.forma.value.paso_helice,
            this.forma.value.material_helice,
            this._usuarioServices.usuario[0].id,
            fecha,
            this.forma.value.direccion,
            this.forma.value.telefono,
            this.forma.value.email,
            this.forma.value.usabilidad,
            this.forma.value.lugar_uso,
            this.forma.value.marca_bote,
            this.forma.value.modelo_bote,
            this.forma.value.slora,
            this.forma.value.manga,
            this.forma.value.material_bote,
            this.forma.value.comentario,
            fecha,
            this.forma.value.otro_uso,
            this.forma.value.filtro_agua,
            this.forma.value.marca_filtro
    );
    // console.log(factura);
    swal( {
      title: "Esta seguro de continuar?",
      text: "Una vez registrado, no es posible cambiar o actualizar los datos",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
        this.facturaService.crearFactura(factura, this.headers)
                       .subscribe( (resp: any) => {
                         this.limpiarFormulario();
                         window.scrollTo(0, 0 );
                       });
      } else {
        swal("OK, vamos a esperar su respuesta");
      }
    });
  }
}

