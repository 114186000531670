import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//Rutas
import { APP_ROUTES } from './app.route';

//Method
import { HttpClientModule } from '@angular/common/http';

//Formularios
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//Component
import { AppComponent } from './app.component';
import { FacturaComponent } from './pages/factura/crear-factura/factura.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Graficas1Component } from './pages/graficas1/graficas1.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { PagesComponent } from './pages/pages.component';
import { RegisterComponent } from './pages/usuario/crear-usuario/register/register.component';
import { UsuarioService } from './services/usuario/usuario.service';
import { RegistrosComponent } from './pages/registros/registros.component';
import { MostrarComponent } from './pages/registros/mostrar/mostrar.component';
import { CrearDistribuidorComponent } from './pages/distribuidores/crear-distribuidor/crear-distribuidor.component';
import { MostrarMotorComponent } from './pages/motor/mostrar-motor/mostrar-motor.component';
import { ActualizarMotorComponent } from './pages/motor/actualizar-motor/actualizar-motor.component';
import { CrearMotorComponent } from './pages/motor/crear-motor/crear-motor.component';
import { MostrarFacturaComponent } from './pages/factura/mostrar-factura/mostrar-factura.component';
import { FacturaService } from './services/factura/factura.service';
import { ActualizarFacturaComponent } from './pages/factura/actualizar-factura/actualizar-factura.component';
import { MostrarUsuarioComponent } from './pages/usuario/mostrar-usuario/mostrar-usuario.component';
import { ActualizarUsuarioComponent } from './pages/usuario/actualizar-usuario/actualizar-usuario.component';
import { MostrarDistribuidorComponent } from './pages/distribuidores/mostrar-distribuidor/mostrar-distribuidor.component';
import { ActualizarDistribuidorComponent } from './pages/distribuidores/actualizar-distribuidor/actualizar-distribuidor.component';
import { MostrarFacturaDesactivadasComponent } from './pages/factura/mostrar-factura-desactivadas/mostrar-factura-desactivadas.component';
import { DistribuidorGuard } from './services/guards/distribuidor.guard';
import { BusquedaComponent } from './pages/busqueda/busqueda.component';
import { BusquedaUsuariosComponent } from './pages/busqueda-usuarios/busqueda-usuarios.component';


//Guard 
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { AdminGuard } from './services/guards/admin.guard';

//Externos
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { ChartsModule } from 'ng2-charts';
import { MostrarMotorDesactivadoComponent } from './pages/motor/mostrar-motor-desactivado/mostrar-motor-desactivado.component';
import { MostrarDistribuidorDesactivadoComponent } from './pages/distribuidores/mostrar-distribuidor-desactivado/mostrar-distribuidor-desactivado.component';
import { MostrarUsuarioDesactivadoComponent } from './pages/usuario/mostrar-usuario-desactivado/mostrar-usuario-desactivado.component';
import { BusquedaDistribuidorComponent } from './pages/busqueda-distribuidor/busqueda-distribuidor.component';
import { MecGuard } from './services/guards/mec.guard';





@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    NopagefoundComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    DashboardComponent,
    FacturaComponent,
    Graficas1Component,
    RegisterComponent,
    RegistrosComponent,
    MostrarComponent,
    CrearDistribuidorComponent,
    MostrarMotorComponent,
    ActualizarMotorComponent,
    CrearMotorComponent,
    MostrarMotorDesactivadoComponent,
    MostrarFacturaComponent,
    ActualizarFacturaComponent,
    MostrarUsuarioComponent,
    ActualizarUsuarioComponent,
    MostrarDistribuidorComponent,
    CrearDistribuidorComponent,
    ActualizarDistribuidorComponent,
    MostrarFacturaDesactivadasComponent,
    MostrarDistribuidorDesactivadoComponent,
    MostrarUsuarioDesactivadoComponent,
    BusquedaComponent,
    BusquedaDistribuidorComponent,
    MostrarDistribuidorDesactivadoComponent,
    PagesComponent,
    BusquedaUsuariosComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    APP_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ChartsModule
  ],
  providers: [ UsuarioService, LoginGuardGuard, FacturaService, AdminGuard, DistribuidorGuard, MecGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
