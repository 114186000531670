import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { URL_SERVICIOS } from '../../../config/config';

import swal from 'sweetalert';
import { Distribuidor } from '../../../models/distribuidor.model';
import { DistribuidorService } from '../../../services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-actualizar-distribuidor',
  templateUrl: './actualizar-distribuidor.component.html',
  styleUrls: ['./actualizar-distribuidor.component.css']
})
export class ActualizarDistribuidorComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  public disAux = [];
  public distri  = [];
  public param: string;

  public empresa: any[];

  forma: FormGroup;

  constructor( private activateRoute: ActivatedRoute,
               private http: HttpClient,
               public distribuidorService: DistribuidorService
               ) {

    this.activateRoute.params.subscribe( params => {
       this.param = params['id'];
      //  console.log(this.param);
    });
  }

  validaRut(rutCompleto: string) {

    return ( group: FormGroup) => {

      let rut_full = group.controls[rutCompleto].value;

      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rut_full ))  {
        return {
          validaRut: true
        }
      }

      let tmp 	= rut_full.split('-');
      let digv	= tmp[1];
      let rut 	= tmp[0];
  
      if ( digv == 'K' ) digv = 'k';

      //VALIDAR DIGITO
      let tmp_dv = this.validaDigitoDv(rut).toString();
      if( digv === tmp_dv) {
        console.log('valido');
        return null;
      }
        console.log('invalido');
        return {
          validaRut: true
        }
     }
   }

  validaDigitoDv(T: any) {
    var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';

  }



  ngOnInit() {

    this.getMotor(this.param);

    this.forma = new FormGroup({
      nombre_empresa  :   new FormControl(null, Validators.required ),
      rut_empresa     :   new FormControl( null, Validators.required),
    }, { validators: this.validaRut('rut_empresa') });

  }

  getMotor(idx: string) {
    const url: string = URL_SERVICIOS+'/distribuidor/'+ idx;
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any[] )  => {

      this.disAux = Object.values(data);
      this.distri = this.disAux[1][0];
      this.empresa =  Object.values(this.distri);

      // console.log(this.empresa[1]);
      // console.log(this.empresa[2]);

      this.forma.setValue({
        nombre_empresa: this.empresa[1],
        rut_empresa : this.empresa[2]
      });

    });
  }

  actualizarDistribuidor( ) {
    // console.log(this.param);
    let dealer = new Distribuidor(
      this.forma.value.nombre_empresa,
      this.forma.value.rut_empresa,
    );

    swal( {
      title: "Esta seguro que desea continuar?",
      text: "Se va actualizar el registro del Distribuidor",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
              this.distribuidorService.updateDistribuidor(dealer, this.param, this.headers).subscribe( ( data: any)  => {});
      } else {
        swal("OK, todo esta bien ");
      }
    });
  }
}
