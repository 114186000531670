import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {
  
  public rol: string ;
  public user: string;

  constructor( public usuarioService: UsuarioService,
               public router: Router) { }

  ngOnInit() {
      this.rol = this.usuarioService.usuario[0].rol;
      this.user = this.usuarioService.usuario[0].usuario;
  }

}
