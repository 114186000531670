import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { URL_SERVICIOS } from '../../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';
import { FacturaService } from '../../../services/factura/factura.service';
import { Factura } from '../../../models/factura.model';


@Component({
  selector: 'app-actualizar-factura',
  templateUrl: './actualizar-factura.component.html',
  styleUrls: ['./actualizar-factura.component.css']
})

export class ActualizarFacturaComponent implements OnInit {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;

  public comunas = [];
  public motores = [];
  public dealer = [];

  public facturaAux = [];
  public factura  = [];
  public param: string;

  public invoice: any[];


  constructor( private activateRoute: ActivatedRoute,
               private http: HttpClient,
               public facturaService: FacturaService
               ) {

      this.activateRoute.params.subscribe( params => {
                  this.param = params['id'];
               });
  }

  validaRut(rutCompleto: string) {

    return ( group: FormGroup) => {

      let rut_full = group.controls[rutCompleto].value;

      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rut_full ))  {
        return {
          validaRut: true
        }
      }

      let tmp 	= rut_full.split('-');
      let digv	= tmp[1];
      let rut 	= tmp[0];
  
      if ( digv == 'K' ) digv = 'k';

      //VALIDAR DIGITO
      let tmp_dv = this.validaDigitoDv(rut).toString();
      if( digv === tmp_dv) {
        // console.log('valido');
        return null;
      }
        // console.log('invalido');
        return {
          validaRut: true
        }
     }
   }

   validaDigitoDv(T: any) {
    var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';

  }

  ngOnInit() {

    this.getDataComunas();
    this.getDataMotores();

    this.forma = new FormGroup({
      factura         : new FormControl( null, Validators.required ),
      fecha_venta     : new FormControl( null, Validators.required),
      nombre_cliente  : new FormControl( null, Validators.required ),
      apellido_cliente: new FormControl( null, Validators.required ),
      rut_cliente     : new FormControl(Validators.required),
      direccion       : new FormControl(),
      telefono        : new FormControl(),
      email           : new FormControl(Validators.email),
      serie           : new FormControl(Validators.required),
      usabilidad      : new FormControl(Validators.required),
      lugar_uso       : new FormControl(),
      paso_helice     : new FormControl(Validators.required),
      marca_helice    : new FormControl(Validators.required),
      material_helice : new FormControl(),
      marca_bote      : new FormControl(),
      modelo_bote     : new FormControl(),
      slora           : new FormControl(),
      manga           : new FormControl(),
      material_bote   : new FormControl(),
      motor           : new FormControl(Validators.required),
      fecha_entrega   : new FormControl(Validators.required),
      comuna          : new FormControl(Validators.required),
      comentario      : new FormControl(),
      otro_uso        : new FormControl(),
      filtro_agua     : new FormControl(),
      marca_filtro    : new FormControl()
    }, { validators: this.validaRut('rut_cliente') });

    this.getFactura(this.param);
  }

  getDataComunas() {
    const url_comunas  = URL_SERVICIOS + '/comunas';
    this.http.get(url_comunas, {headers: this.headers})
      .subscribe( ( data: any)  => {
        this.comunas = Object.values(data);
      });
  }

  getDataMotores() {
    const url_motores  = URL_SERVICIOS + '/motor';
    this.http.get(url_motores, {headers: this.headers})
      .subscribe( ( data: any)  => {
        this.motores = Object.values(data);
      });
  }

  private appendLeadingZeroes(n: any) {
    if( n <= 9) {
      return "0" + n;
    }
    return n;
  }

  getFactura(idx: string) {
    const url: string = URL_SERVICIOS + '/factura/' + idx;

    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.facturaAux = Object.values(data);
      this.factura = this.facturaAux[1][0];
    
      this.invoice = Object.values(this.factura);
      
      // console.log(this.invoice);
      
      // AJUSTAR VALORES
      this.forma.setValue ({
      factura         : this.invoice[1],
      fecha_venta     : this.invoice[2],
      nombre_cliente  : this.invoice[4],
      apellido_cliente: this.invoice[6],
      rut_cliente     : this.invoice[5],
      direccion       : this.invoice[7],
      telefono        : this.invoice[8],
      email           : this.invoice[44],
      serie           : this.invoice[11],
      usabilidad      : this.invoice[13],
      lugar_uso       : this.invoice[14],
      paso_helice     : this.invoice[15],
      marca_helice    : this.invoice[16],
      material_helice : this.invoice[17],
      marca_bote      : this.invoice[18],
      modelo_bote     : this.invoice[19],
      slora           : this.invoice[20],
      manga           : this.invoice[21],
      material_bote   : this.invoice[22],
      motor           : this.invoice[24],
      fecha_entrega   : this.invoice[3],
      comuna          : this.invoice[25],
      comentario      : this.invoice[28],
      otro_uso        : this.invoice[29],
      filtro_agua     : this.invoice[6],
      marca_filtro    : this.invoice[12]
      });
    });
  }

  actualizarFactura() {
    
    if (!this.forma.valid) return;
    
        

    const hoy = new Date();
    let fecha: string = hoy.getFullYear()+'-'+(hoy.getMonth() + 1)+'-'+hoy.getDate();

    let _factura  = ( {
      factura         : this.forma.value.factura,
      fecha_venta     : this.forma.value.fecha_venta,
      nombre_cliente  : this.forma.value.nombre_cliente,
      apellido_cliente: this.forma.value.apellido_cliente,
      rut_cliente     : this.forma.value.rut_cliente,
      direccion       : this.forma.value.direccion,
      telefono        : this.forma.value.telefono,
      email           : this.forma.value.email,
      serie           : this.forma.value.serie,
      usabilidad      : this.forma.value.usabilidad,
      lugar_uso       : this.forma.value.lugar_uso,
      paso_helice     : this.forma.value.paso_helice,
      marca_helice    : this.forma.value.marca_helice,
      material_helice : this.forma.value.material_helice,
      marca_bote      : this.forma.value.marca_bote,
      modelo_bote     : this.forma.value.modelo_bote,
      slora           : this.forma.value.slora,
      manga           : this.forma.value.manga,
      material_bote   : this.forma.value.material_bote,
      motor           : this.forma.value.motor,
      fecha_entrega   : this.forma.value.fecha_entrega,
      comuna          : this.forma.value.comuna,
      comentario      : this.forma.value.comentario,
      update_at       : fecha,
      otro_uso        : this.forma.value.otro_uso,
      filtro_agua     : this.forma.value.filtro_agua,
      marca_filtro    : this.forma.value.marca_filtro
    });

    // console.log(_factura);

    swal( {
      title: "Esta seguro que desea continuar?",
      text: "Se va actualizar el registro de Nota de venta",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    } as any)
      .then((willDelete) => {
      if (willDelete) {
                this.facturaService.updateFactura( _factura , this.param, this.headers)
                .subscribe( (resp: any) => {});
      } else {
        swal("OK, todo esta bien ");
      }
    });

  }


}
