import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';

@Component({
  selector: 'app-mostrar-distribuidor-desactivado',
  templateUrl: './mostrar-distribuidor-desactivado.component.html',
  styleUrls: ['./mostrar-distribuidor-desactivado.component.css']
})
export class MostrarDistribuidorDesactivadoComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  pageActual: number = 1;
  public distribuidores = [];
  public totalRegistro;

  constructor( private http:HttpClient ) { }

  ngOnInit() {
    this.getDataDistribuidor();
  }

  getDataDistribuidor() {
    const url: string = URL_SERVICIOS + '/dealers/desactivados';
    this.distribuidores = [];
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.distribuidores = Object.values(data);
      this.totalRegistro  = Object.keys(this.distribuidores[1]).length;
    });
  }

  Activar( id: any ) {
    this.getDataDistribuidor();
    let turn_off = ({
         condicion: 0,
       });
     swal( {
         title: "Esta seguro de continuar?",
         text:  "Se va a habilitar este registro",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       } as any)
         .then((willDelete) => {
         if (willDelete) {
             const url: string = URL_SERVICIOS + '/activador/distribuidor/' + id;
               this.http.put(url, turn_off, {headers: this.headers}).subscribe( ( data: any)  => {
                 this.getDataDistribuidor();
               });
           swal("La acción se ha completado ...", {
             icon: "success",
           });
         } else {
           swal("OK, vamos a esperar su respuesta");
         }
       });
 
   }

}
