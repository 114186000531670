import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Distribuidor } from '../../models/distribuidor.model';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class DistribuidorService {
  
  headers = new HttpHeaders().set('token', localStorage.getItem('token'));


  constructor( private http: HttpClient) { }

  crearDistribuidor( distribuidor: Distribuidor , header: any) {

    const url: string = URL_SERVICIOS + '/dealer/register';
    return this.http.post( url , JSON.parse(JSON.stringify(distribuidor)), {headers: header})
                            .map( (resp: any) => { console.log(resp); })
                            .catch( err => {
                              console.log( err.error.error.sqlMessage );
                              swal('Error', err.error.error.sqlMessage , 'error');
                              return Observable.throw( err )});
  }

  updateDistribuidor( distribuidor: Distribuidor, idx: string , header: any) {

    const url: string = URL_SERVICIOS + '/actualizar/distribuidor/' + idx;

    return this.http.put(url, distribuidor,  {headers: header })
                            .map( (resp: any) => { 
                              swal("La actualización se ha completado", {
                                icon: "success",
                              });
                            })
                            .catch( err => {
                              console.log( err.error.error.sqlMessage );
                              swal('Error', err.error.error.sqlMessage , 'error');
                              return Observable.throw( err )});
  }
}
