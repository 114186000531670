import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FacturaService } from '../../services/factura/factura.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-busqueda-distribuidor',
  templateUrl: './busqueda-distribuidor.component.html',
  styles: []
})
export class BusquedaDistribuidorComponent implements OnInit {

  headers = new HttpHeaders().set('token', localStorage.getItem('token'));

  forma: FormGroup;
  public facturas = [];
  pageActual: number = 1;
  public totalRegistro;
  public termino;

  //LOADING
  loading: boolean = true;

  constructor( public activatedRoute: ActivatedRoute,
               public facturaService: FacturaService,
               public usuarioService: UsuarioService,
               private http: HttpClient,
               private router: Router) {
                activatedRoute.params
                .subscribe( params => {
                  this.termino = params['termino'];
                  if (this.termino === '') {
                    this.router.navigate(['/registros']);
                  }
                  this.getDataBuscar(this.termino);
                });
   }

  ngOnInit() {

  }

  getDataBuscar( termino: string ){
    let url = URL_SERVICIOS + '/factura/buscar/' + this.usuarioService.usuario[0].dealer_id + '/' + termino;
    this.facturas = [];
    this.http.get(url, {headers: this.headers})
             .subscribe( ( data: any ) => {
                this.facturas = Object.values(data);
                this.totalRegistro = Object.keys(this.facturas[1]).length;
                setTimeout(() => {
                  this.loading = false;
                }, 500);
             });
  }

  getDataFacturas() {
    const url: string = URL_SERVICIOS + '/registro/' + this.usuarioService.usuario[0].dealer_id;
    this.http.get(url, {headers: this.headers}).subscribe( ( data: any)  => {
      this.facturas = Object.values(data);
      this.totalRegistro = Object.keys(this.facturas[1]).length;
      setTimeout(() => {
        this.loading = false;
      }, 300);
    });
  }

  buscar( termino: string ) {
    this.router.navigate(['/buscar/venta', termino]);
  }

}
